<template>
  <PageWithLayout
    :left-right-padding="20"
    :top-padding="16"
    :bottom-padding="20"
    :is-close-btn="true"
    :is-close-web-end="true"
  >
    <!-- :close-route-query="$route.query"
    :close-page="$route.query.closePage" -->
    <div class="container">
      <h1 class="tc_black">‘저금통’ 이용 안내</h1>
      <div class="content">
        <div>
          <h2 class="tc_black">‘저금통’ 서비스 소개</h2>
          <ul class="tc_gray">
            <li>
              내 지갑(퍼핀카드)과 별도로 보관용도의 돈통을 만들어 돈을 모을 수
              있는 서비스예요.
            </li>
            <li>저금통은 자녀 사용자만 만들 수 있어요.</li>
            <li>
              저금통에 최대 30만원까지의 목표금액을 설정하여 모을 수 있어요.
            </li>
            <li>
              여러 개의 저금통을 동시에 만들어 모을 수는 없고, 한 번에 1개의
              목표만 만들 수 있어요.
            </li>
            <li>
              [저금하기]를 통해 자녀 본인의 ‘내 지갑’ 잔액 안에서 원하는
              금액만큼 저금통으로 옮겨서 돈을 모을 수 있어요.
            </li>
            <li>
              퍼핀에 연결된 부모님 사용자는 부모님 앱의 [보태주기]를 통해
              ‘부모님의 지갑’ 잔액 안에서 원하는 금액만큼 자녀분의 저금통으로
              보태주실 수 있어요.
            </li>
          </ul>
        </div>
        <div>
          <h2 class="tc_black">유의사항</h2>
          <ul class="tc_gray">
            <li>
              저금통 서비스는 선불전자지급수단 기반의 서비스로, 자녀 사용자의
              선불 보유 한도는 최대 50만원이에요.
            </li>
            <li>
              자녀 사용자의 ‘저금통’ 및 ‘내 지갑’에 보유한 선불금액 총합이
              50만원을 넘는 경우,
            </li>
            <ul>
              <li>‘저금통’에 부모님의 보태주기가 제한돼요.</li>
              <li>
                자녀의 ‘내 지갑’으로 학습보상 / 추가용돈 / 정기용돈을 받는 것도
                제한돼요.
              </li>
            </ul>
            <li>
              목표금액 달성 후에는 [끝내기]를 통해 자녀사용자 본인이 저금한
              금액과 부모님이 보태주신 저금 보너스 모두 내 지갑으로 옮길 수
              있어요.
            </li>
            <li>
              목표금액 달성전에 언제든지 저금 목표를 [중도 포기]할 수 있어요.
            </li>
            <ul>
              <li>
                중도 포기 시, 자녀 사용자 본인이 직접 저금한 금액은 전부 내
                지갑으로 옮길 수 있어요.
              </li>
              <li>
                중도 포기 시, 부모님이 보태주신 저금 보너스는 각 부모님께 도로
                돌려드려요.
              </li>
              <li>
                단, 중도 포기라 하더라도 부모님 계정이 탈퇴되었거나, 부모님의
                지갑 보유 한도가 넘는 등, 부모님께 돌려드릴 수 없는 예외
                상황에서는 부모님표 저금 보너스도 자녀 사용자분의 지갑으로
                옮겨져요.
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';

export default {
  name: 'PiggyBankGuide',
  components: {
    PageWithLayout,
  },
  mixins: [InterFaceMixin],
};
</script>

<style scoped>
h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px; /* 140% */
  margin-bottom: 16px;
}

h2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
}

ul {
  padding-left: 24px; /* 불릿과 텍스트 간격 조정 */

  font-feature-settings: 'clig' off, 'liga' off;
  /* KR/body/medium */
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

li {
  list-style: disc;
}

li::marker {
  font-size: 0.8em;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
